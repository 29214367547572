import * as React from 'react';
import * as ReactDOM from 'react-dom';
require('./styles.css');
export default function alert(text, stype, time) {
    var divDOM = document.createElement('div');
    document.body.appendChild(divDOM);
    ReactDOM.render(React.createElement("div", { className: "global-alert", style: stype ? stype : null },
        React.createElement("div", null, text)), divDOM);
    setTimeout(function () {
        ReactDOM.unmountComponentAtNode(divDOM);
    }, time ? time : 2000);
}
