// @ts-nocheck
import is from '../lib/ua';
import * as qs from 'qs';
// import * as Sentry from '@sentry/browser';
import { checkToken, fetchWXLogin, transformToCheeseToken } from './index';
import alertV2 from '../lib/alertV2';
var APPID = 'wxe7da975ba0165ae5';
if (process.env.NODE_ENV !== 'production') {
    APPID = 'wxe7def3c23d606a8d';
}
var REDIRECT_URI = encodeURIComponent(window.location.origin + location.pathname + location.search);
var SCOPE = 'snsapi_userinfo';
var pathname = qs.parse(window.location.pathname, { ignoreQueryPrefix: true });
export default function auth() {
    return new Promise(function (resovle, reject) {
        var token = localStorage.getItem('token');
        var query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        var code = query.code, inviteCode = query.inviteCode, clear = query.clear, type = query.type;
        var coes;
        if (Array.isArray(code)) {
            coes = code[1];
        }
        else {
            coes = code;
        }
        if (clear) {
            localStorage.removeItem('token');
            reject();
        }
        if (Object.keys(pathname)[0].slice(1, 8) == 'hbStock' || Object.keys(pathname)[0].slice(1, 9) == 'notfound') {
        }
        else {
            if (!token) {
                //给测试加的测试token
                if (query.tokenTest) {
                    var tokenQuery = String(query.tokenTest);
                    checkToken({ testToken: tokenQuery }).success(function (res) {
                        var resData = res.data.datas;
                        if (resData) {
                            localStorage.setItem('token', resData);
                        }
                        else {
                            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + APPID + "&redirect_uri=" + REDIRECT_URI + "&response_type=code&scope=" + SCOPE + "&state=auth#wechat_redirect";
                            reject();
                        }
                    }).start();
                }
                else if (query.t_hb) {
                    var hbIncludesPath = ['/stock', '/hbAggP'];
                    var hbPath = window.location.pathname;
                    if (hbIncludesPath.includes(hbPath)) {
                        var hbToken = query.t_hb;
                        transformToCheeseToken({ token: hbToken }).success(function (res) {
                            var _a, _b;
                            var cheeseToken = (_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.datas) === null || _b === void 0 ? void 0 : _b.token;
                            if (cheeseToken) {
                                console.log(cheeseToken, 'hbtoken_first');
                                localStorage.setItem('token', cheeseToken);
                                window.sessionStorage.setItem('hbToken', cheeseToken);
                                resovle();
                            }
                            else {
                            }
                        }).failure(function (res) {
                        }).start();
                    }
                    else {
                        reject();
                    }
                }
                else if (is.wechat) {
                    if (window.location.href.indexOf('state=auth') >= 0) {
                        code && fetchWXLogin({
                            code: coes,
                            inviteCode: inviteCode,
                            type: type
                        }).success(function (res) {
                            try {
                                var _a = res.data.datas, token_1 = _a.token, user = _a.user, userText_1 = _a.userText;
                                if (userText_1) {
                                    setTimeout(function () {
                                        alertV2(userText_1);
                                    }, 1000);
                                }
                                if (token_1 && user) {
                                    localStorage.setItem('token', token_1);
                                    localStorage.setItem('user', JSON.stringify(user));
                                    resovle();
                                }
                            }
                            catch (e) {
                                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + APPID + "&redirect_uri=" + REDIRECT_URI + "&response_type=code&scope=" + SCOPE + "&state=auth#wechat_redirect";
                                reject();
                            }
                        }).start();
                    }
                    else {
                        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + APPID + "&redirect_uri=" + REDIRECT_URI + "&response_type=code&scope=" + SCOPE + "&state=auth#wechat_redirect";
                        reject();
                    }
                }
                else {
                    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + APPID + "&redirect_uri=" + REDIRECT_URI + "&response_type=code&scope=" + SCOPE + "&state=auth#wechat_redirect";
                }
            }
            else {
                if (query.t_hb) {
                    var hbIncludesPath = ['/stock', '/hbAggP'];
                    var hbPath = window.location.pathname;
                    if (hbIncludesPath.includes(hbPath)) {
                        var hbToken = query.t_hb;
                        transformToCheeseToken({ token: hbToken }).success(function (res) {
                            var _a, _b;
                            var cheeseToken = (_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.datas) === null || _b === void 0 ? void 0 : _b.token;
                            if (cheeseToken) {
                                localStorage.setItem('token', cheeseToken);
                                window.sessionStorage.setItem('hbToken', cheeseToken);
                                resovle();
                            }
                            else {
                            }
                        }).failure(function (res) {
                        }).start();
                    }
                    else {
                        reject();
                    }
                }
                var userString = localStorage.getItem('user');
                resovle();
            }
        }
    });
}
