var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as qs from 'qs';
import auth from '../../service/wechatAuth';
import { isApp } from "../utils";
import is from "../ua";
import { iosAndroid } from "../appOc";
import alert from "../alert/index";
import store from '../../store';
import { hex_md5 } from "../md5";
import { handleStringToArr_length } from "../../index";
import { AESEncrypt } from '../crypto';
import alertV2 from "../alertV2";
import { getTimesTamp } from '../../lib/utils';
var Tinker = /** @class */ (function () {
    function Tinker(params) {
        if (!params.dataType) {
            params.dataType = 'json';
        }
        this.engine = Tinker.engine;
        this.params = params;
        this.successCalBack = function () { };
        this.failCallBack = function () { };
    }
    Tinker.prototype.start = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var result, msg, resData, statusCode, favorite_boo_1, favoriteArr;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Tinker.engine(this.params)];
                    case 1:
                        result = _b.sent();
                        if (((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.code) === '-002') {
                            msg = result.data.message;
                            alertV2(msg);
                        }
                        if (this.params.url == '/api/v2/user/userinfo') {
                            resData = result.data.datas;
                            if (resData) {
                                store.dispatch({
                                    type: 'change_userInfo',
                                    value: resData
                                });
                            }
                            store.dispatch({ type: 'change_isGetNewUserCard', value: 'not' });
                        }
                        //OSS 上传图片 statusCode == 200  即为成功 否则 失败
                        if (this.params.ContentType == "multipart/form-data;") {
                            statusCode = result.statusCode;
                            if (statusCode == 200) {
                                this.successCalBack && this.successCalBack(result);
                                return [2 /*return*/];
                            }
                            else {
                                throw result;
                                return [2 /*return*/];
                            }
                        }
                        if (result && result.data && result.data.code == '014') {
                            sessionStorage.setItem('newUser', '1');
                            this.successCalBack && this.successCalBack(result);
                        }
                        if (result.data.code == '037') {
                            alert(result.data.message);
                            throw result;
                        }
                        if (Tinker.isSuccess(result)) {
                            favorite_boo_1 = false;
                            favoriteArr = ['/api/v2/opt/addOptGroup',
                                '/api/v2/opt/delOptGroup',
                                '/api/v2/opt/renameOptGroup',
                                '/api/v2/opt/sortOptGroup',
                                '/api/v2/opt/addOpt',
                                '/api/v2/opt/batchDelOpt',
                                '/api/v2/opt/moveOpt',
                                '/api/v2/opt/sortOpt',
                                '/api/v2/opt/reSetAddDate',
                                '/api/v2/opt/setTop',
                                '/api/v2/capital/addOptional',
                                '/api/v2/capital/sortOptional',
                                '/api/v2/capital/batchDelOptional',
                                '/api/v2/capital/delOptional',
                                '/api/v2/user/pushSet'];
                            favoriteArr.forEach(function (item, index) {
                                if (_this.params.url.indexOf(item) != -1) {
                                    favorite_boo_1 = true;
                                }
                            });
                            if (favorite_boo_1) {
                                window.sessionStorage.setItem('break_favorite', '1');
                            }
                            this.successCalBack && this.successCalBack(result);
                        }
                        else {
                            /**
                             * NO_USER("004", "用户未登录-跳转登录页"),
                             * JWT_EXPIRE("015", "token超时"),
                             *JWT_FAIL("016", "token失败"),
                             *OTHER_LOGIN("033", "账号已经在别处登录或是IP发生了变化,请重新登录"),
                             *USER_NO_EXIST("036", "用户不存在"),
                             */
                            if (result && result.data &&
                                (result.data.code == "004"
                                    || result.data.code == "015"
                                    || result.data.code == "016"
                                    || result.data.code == "033"
                                    || result.data.code == "036")) {
                                if (isApp) {
                                    localStorage.removeItem('token');
                                    iosAndroid('navTo', { view: 'relogin' });
                                }
                                else {
                                    localStorage.removeItem('token');
                                    auth();
                                }
                            }
                            if (result) {
                                this.failCallBack && this.failCallBack(result);
                            }
                            if (result.statusCode !== 200 || !result.data || (result.data && (result.data.code !== '000' && result.data.code !== '043' || result.data.code == 'HB403'))) {
                                //Sentry.captureException(new Error(JSON.stringify(result)));
                                throw result;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Tinker.prototype.success = function (successCalBack) {
        this.successCalBack = successCalBack;
        return this;
    };
    Tinker.prototype.failure = function (failCallBack) {
        this.failCallBack = failCallBack;
        return this;
    };
    return Tinker;
}());
Tinker.engine = function (params) {
    return __awaiter(this, void 0, void 0, function () {
        var arrWhiteList, url, substringBeforeChar, urlChart, result, search, data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    arrWhiteList = ['/api/v2/user/userinfo'];
                    url = params.url;
                    urlChart = url.indexOf('?');
                    if (urlChart !== -1) {
                        substringBeforeChar = url.substring(0, urlChart);
                    }
                    else {
                        substringBeforeChar = url;
                    }
                    if (url && url.includes('/api/v4/')) {
                        if (params.data) {
                            params.data['t'] = getTimesTamp();
                        }
                        else {
                            params['data'] = {};
                            params['data']['t'] = getTimesTamp();
                        }
                    }
                    result = null;
                    search = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                    if (!(params.header && params.header.method === 'GET')) return [3 /*break*/, 2];
                    if (search._source && arrWhiteList.includes(substringBeforeChar)) {
                        params.data._source = search._source;
                    }
                    if (params.data) {
                        url = url + "?" + qs.stringify(params.data);
                    }
                    return [4 /*yield*/, window.fetch(url, {
                            headers: __assign({}, Tinker.getHeader(params))
                        })];
                case 1:
                    result = _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    if (!(params.header && params.header.method === 'POST')) return [3 /*break*/, 4];
                    if (search._source && arrWhiteList.includes(substringBeforeChar)) {
                        url = url + "?_source=" + search._source;
                    }
                    return [4 /*yield*/, window.fetch(url, {
                            headers: __assign({}, Tinker.getHeader(params)),
                            method: 'POST',
                            body: params.ContentType ? params.data : JSON.stringify(params.data),
                        })];
                case 3:
                    result = _a.sent();
                    _a.label = 4;
                case 4:
                    _a.trys.push([4, 9, , 10]);
                    if (!(params.dataType === 'json')) return [3 /*break*/, 6];
                    return [4 /*yield*/, result.json()];
                case 5:
                    data = _a.sent();
                    return [3 /*break*/, 8];
                case 6: return [4 /*yield*/, result.text()];
                case 7:
                    data = _a.sent();
                    _a.label = 8;
                case 8: return [3 /*break*/, 10];
                case 9:
                    e_1 = _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/, {
                        statusCode: result.status,
                        data: data,
                    }];
            }
        });
    });
};
Tinker.isSuccess = function (result) {
    return result && result.statusCode === 200 && result.data && (result.data.code === '000' || result.data.code === '043' || result.data.code === 'HB403' || result.data.code === '035' || result.data.code === '014' || result.data.code === '025' || result.data.code === '034' || result.data.code === '500');
};
Tinker.getHeader = function (params) {
    var url = params === null || params === void 0 ? void 0 : params.url;
    var boo = url ? url.includes('/v3/') : false;
    //判断PC端还是移动端 true为移动端 false为pc端
    function isMobile() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
        var getArr = Agents.filter(function (i) { return userAgentInfo.includes(i); });
        return getArr.length ? true : false;
    }
    function renderDefault() {
        if (isApp) {
            if (is.iPhone) {
                return 'ios';
            }
            else {
                return 'android';
            }
        }
        else {
            if (isMobile()) {
                return 'wechat';
            }
            else {
                return 'other';
            }
        }
    }
    function renderRequestDefault() {
        // isApp ? is.iosIpad ?  'ios-ipad'  : is.iPhone ? 'ios' : 'android' :  'wechat' ,
        if (isApp) {
            if (is.iosIpad) {
                return 'ios-ipad';
            }
            else if (is.iPhone) {
                return 'ios';
            }
            else {
                return 'android';
            }
        }
        else {
            if (isMobile()) {
                return 'wechat';
            }
            else {
                return 'other';
            }
        }
    }
    if (params.ContentType) {
        return {
            token: localStorage.getItem('token'),
            'requestFrom': renderDefault(),
            'Expires': -1
        };
    }
    else {
        var isSubject = window.sessionStorage.getItem('isSubject');
        var apiLockParams = {};
        if (boo) {
            var timer = new Date().getTime();
            var userToken = sessionStorage.getItem('userToken');
            var ApiList = handleStringToArr_length(userToken, 8);
            var AESHttpData = AESEncrypt(ApiList[timer % 10], 'vGEZCiIXRIImAWSv');
            var key = hex_md5(timer + AESHttpData);
            var apiLockInfo = store.getState().APILOCK;
            apiLockParams = {
                zstokv1: apiLockInfo ? key : '',
                timeStamp: apiLockInfo ? timer : '',
            };
        }
        return isSubject ? __assign({ token: localStorage.getItem('token'), 'requestFrom': renderDefault(), 'Content-Type': 'application/json;charset=utf-8', 'Expires': -1, 'COURSE-SOFT-TOKEN': 'COURSE' }, (boo ? apiLockParams : null)) : __assign({ token: localStorage.getItem('token'), 'requestFrom': renderRequestDefault(), 'Content-Type': 'application/json;charset=utf-8', 'Expires': -1 }, (boo ? apiLockParams : null));
    }
};
export default Tinker;
