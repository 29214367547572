export var clearCache_config = [
    {
        cacheKey: 'rankList_content',
        clearPath: ['/rankList', '/', '/favorite', '/fundHome'],
    },
    {
        cacheKey: 'chooseResult',
        clearPath: ['/ChooseNew', '/chooseNewFund', '/chooseFund', '/']
    },
    {
        cacheKey: 'chooseSuper',
        clearPath: ['/ChooseSuperdex', '/chooseindex', '/chooseMeList']
    },
    {
        cacheKey: 'ChooseResultSpuer',
        clearPath: ['/ChooseSuperdex', '/chooseSuper', '/chooseindex', '/chooseMeList']
    },
    {
        cacheKey: 'IndexBoard',
        clearPath: ['/']
    },
    {
        cacheKey: 'researchAndNotice',
        clearPath: ['/favorite', '/']
    },
    {
        cacheKey: 'ImportantEvents',
        clearPath: ['/', '/favorite', '/stock']
    },
    {
        cacheKey: 'FundManCompare',
        clearPath: ['/fundDetails']
    },
    {
        cacheKey: 'componentStockList',
        clearPath: ['/indexStock']
    },
    {
        cacheKey: 'InstitutionalList',
        clearPath: ['/', '/favorite', '/stock']
    },
    {
        cacheKey: 'veryHoldInfo',
        clearPath: ['/', '/favorite']
    },
    {
        cacheKey: 'dailyPaper',
        clearPath: ['/']
    },
    {
        cacheKey: 'dailyPape',
        clearPath: ['/']
    },
    {
        cacheKey: 'newUserGuide',
        clearPath: ['/', '/favorite']
    },
    {
        cacheKey: 'newStockDetail',
        clearPath: ['/', '/favorite']
    },
];
