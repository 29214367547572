import { getWXconfig } from '../service/index';
import is from './ua';
var wx;
wx = window.wx;
function closeChangeFontSize() {
    if (typeof window.WeixinJSBridge == "object" && typeof window.WeixinJSBridge.invoke == "function") {
        handleFontSize();
    }
    else {
        if (document.addEventListener) {
            document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
        }
        else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", handleFontSize);
            document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
        }
    }
}
;
function handleFontSize() {
    if (!window.WeixinJSBridge)
        return;
    // 设置网页字体为默认大小
    window.WeixinJSBridge && window.WeixinJSBridge.invoke('setFontSizeCallback', {
        'fontSize': 0
    });
    // 重写设置网页字体大小的事件
    window.WeixinJSBridge && window.WeixinJSBridge.on('menu:setfont', function () {
        window.WeixinJSBridge.invoke('setFontSizeCallback', {
            'fontSize': 0
        });
    });
}
closeChangeFontSize();
export var wechatShare = function (url, configParams) {
    if (!is.wechat) {
        return;
    }
    if (!wx)
        return;
    getWXconfig({ url: url }).success(function (res) {
        var result = res.data.datas;
        result && wx && wx.config({
            // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline', 'hideMenuItems', 'showMenuItems'],
        });
    }).start();
    wx && wx.ready(function () {
        //朋友圈
        wx.onMenuShareTimeline({
            title: configParams.title,
            link: configParams.link,
            imgUrl: configParams.imgUrl,
            fail: function () {
            }
        });
        //朋友
        wx.onMenuShareAppMessage({
            title: configParams.title,
            desc: configParams.desc,
            link: configParams.link,
            imgUrl: configParams.imgUrl,
            type: 'link',
            dataUrl: '',
            success: function () {
            },
            fail: function () {
            }
        });
        wx.error(function () {
        });
    });
};
export var weSubscribe = function (url) {
    if (!is.wechat) {
        return;
    }
    if (!wx)
        return;
    getWXconfig({ url: url }).success(function (res) {
        var result = res.data.datas;
        result && wx && wx.config({
            // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline'],
            openTagList: ['wx-open-subscribe'],
        });
    }).start();
    wx && wx.ready(function () {
    });
};
export var hideWxLeftBtn = function (url) {
    if (!is.wechat) {
        return;
    }
    if (!wx)
        return;
    getWXconfig({ url: url }).success(function (res) {
        var result = res.data.datas;
        result && wx && wx.config({
            // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: ['hideMenuItems', 'showMenuItems'],
            openTagList: ['wx-open-subscribe'],
        });
    }).start();
    wx && wx.ready(function () {
        wx.hideMenuItems({
            menuList: ['menuItem:copyUrl'] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
        });
    });
};
export var showWxLeftBtn = function (url) {
    if (!is.wechat) {
        return;
    }
    if (!wx)
        return;
    getWXconfig({ url: url }).success(function (res) {
        var result = res.data.datas;
        result && wx && wx.config({
            // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: ['hideMenuItems', 'showMenuItems'],
            openTagList: ['wx-open-subscribe'],
        });
    }).start();
    wx.showMenuItems({
        menuList: ['menuItem:copyUrl'] // 要显示的菜单项，所有menu项见附录3
    });
};
