import * as React from 'react';
import { Router, Route } from 'react-router-dom';
import * as qs from 'qs';
import { CacheRoute, CacheSwitch, dropByCacheKey, getCachingKeys } from 'react-router-cache-route';
import { createBrowserHistory } from 'history';
import { isApp } from "./lib/utils";
import { iosAndroid } from "./lib/appOc";
import { clearCache_config } from './routerCacheClear';
import { routesConfig } from './routesConfig';
//全局注册微信分享的 地址
window.sessionStorage.setItem('wechatShare_Url', location.href);
var query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
//是否在课程中显示页面
var isSubject = query.isSubject;
if (isSubject) {
    window.sessionStorage.setItem('isSubject', String(isSubject));
}
else {
    if (window.sessionStorage.getItem('isSubject')) {
        window.sessionStorage.removeItem('isSubject');
    }
}
export var history = createBrowserHistory();
var pathName = history.location.pathname;
history.listen(function (route, action) {
    //卸载缓存
    var clearConfig = clearCache_config;
    clearConfig.forEach(function (item, index) {
        var cacheArr = getCachingKeys(); //获取已缓存的页面的 cacheKey
        if (cacheArr.length > 0 && cacheArr.includes(item.cacheKey) && item.clearPath.includes(route.pathname) && action == 'PUSH') {
            console.log(item.cacheKey, 'drop');
            dropByCacheKey(item.cacheKey);
        }
    });
    //关闭全局弹框
    var dom = document.querySelector('#alertNoticeV2');
    if (dom) {
        document.body.removeChild(dom);
    }
    if (isApp) {
        iosAndroid('url', { url: encodeURI(route.pathname + route.search) });
    }
    pathName = route.pathname;
    if (pathName != '/stock' && pathName != '/indexStock' && pathName != '/stockHk' && pathName != '/fundDetails' && pathName != '/etfDetails') {
        sessionStorage.removeItem('ke');
        sessionStorage.removeItem('ks');
    }
    var roupn = sessionStorage.getItem('roupn');
    if (roupn) {
        roupn = JSON.parse(roupn);
        if (roupn.length > 0) {
            if (roupn.length == 1) {
                roupn.push(route);
            }
            else {
                roupn[0] = roupn[1];
                roupn[1] = route;
            }
            sessionStorage.setItem('roupn', JSON.stringify(roupn));
        }
    }
    else {
        sessionStorage.setItem('roupn', JSON.stringify([route]));
    }
});
export var routes = (React.createElement(Router, { history: history },
    React.createElement(CacheSwitch, null, routesConfig.map(function (item, index) {
        return item.cacheWhen ? (React.createElement(CacheRoute, { key: index, path: item.path, component: item.component, when: item.cacheWhen, cacheKey: item.cacheKey ? item.cacheKey : null, exact: true })) : (React.createElement(Route, { path: item.path, component: item.component, key: index, exact: true }));
    }))));
