import Tinker from "../lib/tinker";
/**
 *  @param params
 */
export var getApiOuth = function () { return new Tinker({
    url: '/api/v2/system/apiOuth',
    header: { method: 'GET' },
    apiLock: true,
    dataType: 'json',
}); };
/**
 * 股东户数
 *  @param params
 */
export var getShareHolderNumber = function (params) { return new Tinker({
    url: '/api/v3/details/holderNumDetails',
    header: { method: 'GET' },
    apiLock: true,
    dataType: 'json',
    data: params
}); };
