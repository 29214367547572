var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var defaultState = {
    userInfo: window.sessionStorage.getItem('userInfo') ? JSON.parse(window.sessionStorage.getItem('userInfo')) : null,
    userIP: window.sessionStorage.getItem('userIP') ? window.sessionStorage.getItem('userIP') : '',
    showInregral: false,
    articlesNewType: window.localStorage.getItem('articlesNewType') ? window.localStorage.getItem('articlesNewType') : '',
    articlesNewScrollTop: window.sessionStorage.getItem('articlesNewScrollTop') ? window.sessionStorage.getItem('articlesNewScrollTop') : '',
    routeLast_pathName: window.localStorage.getItem('lastRouter_pathName') ? JSON.parse(window.localStorage.getItem('lastRouter_pathName')) : [],
    downLoadAppFromURL: window.sessionStorage.getItem('downLoadAppFromURL') ? window.sessionStorage.getItem('downLoadAppFromURL') : '',
    sendAutoorize_downApp: {
        sendAutoorize_downApp_All: 0,
        sendAutoorize_downApp_phone: 0,
        sendAutoorize_downApp_address: 0,
    },
    presentCard: window.sessionStorage.getItem('presentCard') ? window.sessionStorage.getItem('presentCard') : null,
    isGetNewUserCard: window.localStorage.getItem('isGetNewUserCard') ? window.localStorage.getItem('isGetNewUserCard') : '',
    APILOCK: window.localStorage.getItem('APILOCK') ? JSON.parse(window.localStorage.getItem('APILOCK')) : '',
};
export default (function (state, action) {
    if (state === void 0) { state = defaultState; }
    if (action.type === 'change_userInfo') {
        var newState = __assign({}, state);
        if (action.value) {
            window.sessionStorage.setItem('userInfo', JSON.stringify(action.value));
        }
        newState.userInfo = action.value;
        return newState;
    }
    if (action.type === 'change_userIP') {
        var newState = __assign({}, state);
        window.sessionStorage.setItem('userIP', action.value);
        newState.userIP = action.value;
        return newState;
    }
    if (action.type === 'change_showInregral') {
        var newState = __assign({}, state);
        newState.showInregral = action.value;
        return newState;
    }
    ;
    if (action.type === 'change_articlesNewType') {
        var newState = __assign({}, state);
        window.localStorage.setItem('articlesNewType', action.value);
        newState.articlesNewType = action.value;
        return newState;
    }
    ;
    if (action.type === 'change_articlesNewScrollTop') {
        var newState = __assign({}, state);
        window.sessionStorage.setItem('articlesNewScrollTop', action.value);
        newState.articlesNewScrollTop = action.value;
        return newState;
    }
    ;
    if (action.type === 'change_lastRouter_pathName') {
        var newState = __assign({}, state);
        window.localStorage.setItem('lastRouter_pathName', JSON.stringify(action.value));
        newState.routeLast_pathName = action.value;
        return newState;
    }
    ;
    // 下载App页面 拉取用户手机号 返回 定位 地址
    if (action.type === 'change_downLoadAppFromURL') {
        var newState = __assign({}, state);
        window.sessionStorage.setItem('downLoadAppFromURL', action.value);
        newState.downLoadAppFromURL = action.value;
        return newState;
    }
    ;
    if (action.type === 'change_sendAutoorize_downApp') {
        var newState = __assign({}, state);
        newState.sendAutoorize_downApp = action.value;
        return newState;
    }
    ;
    if (action.type === 'change_presentCard') {
        var newState = __assign({}, state);
        window.sessionStorage.setItem('presentCard', JSON.stringify(action.value));
        newState.presentCard = JSON.stringify(action.value);
        return newState;
    }
    ;
    if (action.type === 'change_isGetNewUserCard') {
        var newState = __assign({}, state);
        if (action.value) {
            window.localStorage.setItem('isGetNewUserCard', action.value);
        }
        else {
            window.localStorage.removeItem('isGetNewUserCard');
        }
        newState.isGetNewUserCard = action.value;
        return newState;
    }
    ;
    if (action.type === 'GET_APILOCK_SUCESS') {
        var newState = __assign({}, state);
        if (action.value) {
            window.localStorage.setItem('APILOCK', action.value ? JSON.stringify(action.value) : '');
        }
        else {
            window.localStorage.removeItem('APILOCK');
        }
        newState.APILOCK = action.value;
        return newState;
    }
    return state;
});
