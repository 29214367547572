import alert from '../../lib/alert';
import { windowHbridge } from "./HBMBBridge.js";
var isProduction = process.env.NODE_ENV === 'production';
// 生产域名: https://m.touker.com
// 仿真域名: https://m-wwwtest.touker.com
// 测试域名  https://m.dev.hbec.com
var useUrl = isProduction ? 'https://m.touker.com' : 'https://m.dev.hbec.com';
export function judgeHbToken() {
    return window.sessionStorage.getItem('hbToken');
}
export function getHbPurchase() {
    window.open(useUrl + "/fd/decisionMaking/purchase?productCode=VZSXG", '_self');
}
export function addToHbFavorite(code, name) {
    var codeArr = code.split('.');
    var url = "qianqian://x-callback-url/stock/addOptional?stockCode=" + codeArr[0] + "&exchange=" + codeArr[1] + "&GroupName=" + name;
    if (windowHbridge && windowHbridge.checkAppRouterValidity()) {
        // 查看最新移动端接口API文档，调用替换的API逻辑
        var params = { 'url': url };
        var method = 'openNativeURL';
        windowHbridge.callApp(method, params, function (err, result) {
            if (err) {
                // error handle
            }
            else {
            }
        });
    }
}
export function cancelHbFavorite(code, name) {
    var codeArr = code.split('.');
    var url = "qianqian://x-callback-url/stock/deleteOptional?stockCode=" + codeArr[0] + "&exchange=" + codeArr[1] + "&GroupName=" + name;
    if (windowHbridge && windowHbridge.checkAppRouterValidity()) {
        // 查看最新移动端接口API文档，调用替换的API逻辑
        var params = { 'url': url };
        var method = 'openNativeURL';
        windowHbridge.callApp(method, params, function (err, result) {
            if (err) {
                // error handle
            }
            else {
            }
        });
    }
}
export function targetToHbPage(code, name) {
    var codeArr = code.split('.');
    var url = "qianqian://x-callback-url/stock/stockQuoteInfo?stockCode=" + codeArr[0] + "&exchange=" + codeArr[1] + "&stockName=" + name;
    if (windowHbridge && windowHbridge.checkAppRouterValidity()) {
        // 查看最新移动端接口API文档，调用替换的API逻辑
        var params = { 'url': url };
        var method = 'openNativeURL';
        windowHbridge.callApp(method, params, function (err, result) {
            if (err) {
                // error handle
            }
            else {
                // success handle
            }
        });
    }
}
function filterFavoriteList(data) {
    if (data) {
        return data === null || data === void 0 ? void 0 : data.values.map(function (item) { return (item.nameValuePairs); });
    }
    else {
        return null;
    }
}
//获取华宝自选股
export function getHbMyFavoriteList(successFn, errorFn) {
    var method = "GetZxgFzInfo"; // 原生方法名
    // if (windowHbridge && windowHbridge.checkAppRouterValidity()) {
    //     // 查看最新移动端接口API文档，调用替换的API逻辑
    //     windowHbridge.callApp(method, {}, function(err, data){
    //         if(err){
    //            //error handle
    //          }else{
    //            //success handle  
    //            const result = filterFavoriteList(data) 
    //            successFn(result) 
    //           }     
    //       });
    // }
    //暂时返回空
    successFn(null);
}
//target to hb fund OTC
export function targetHbOTCFund(code, successFn, errorFn) {
    var method = 'pushWebview';
    var url = useUrl + "/mall/funddetail?product_code=" + code;
    if (windowHbridge && windowHbridge.checkAppRouterValidity()) {
        var params = { 'url': url };
        windowHbridge.callApp(method, params, function (err, data) {
            if (err) {
                alert('该基金暂不支持详情查看');
            }
            else {
            }
        });
    }
}
