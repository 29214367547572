import Tinker from '../lib/tinker/index';
var rootURL = 'https://ssl.newbeestudio.com';
var thirdURL = 'https://api.money.126.net';
/**
 *  判断是否为大陆ip
 *  @param params
 */
export var isCN = function () { return new Tinker({
    url: '/api/v2/user/isCN',
    header: { method: 'GET' },
    dataType: 'json',
}); };
