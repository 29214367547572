var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CSS_Transition from '../../components/cssTransition/index';
require('./style.css');
export default function alertV2(text, type, style) {
    var divDOM = document.createElement('div');
    divDOM.id = 'alertV2';
    if (style && style.top) {
        divDOM.style.top = style.top;
    }
    var dom = document.querySelector('#alertV2');
    if (dom) {
        document.body.removeChild(dom);
        document.body.appendChild(divDOM);
    }
    else {
        document.body.appendChild(divDOM);
    }
    var alertV2 = setTimeout(function () {
        if (alertV2) {
            clearTimeout(alertV2);
        }
        ReactDOM.unmountComponentAtNode(divDOM);
    }, 4000);
    ReactDOM.render(React.createElement(AlertV2, { text: text, type: type ? type : 'text', style: style }), divDOM);
}
var AlertV2 = /** @class */ (function (_super) {
    __extends(AlertV2, _super);
    function AlertV2() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            show: false
        };
        _this.timer_removeDom = null;
        _this.boxCont = React.createRef();
        return _this;
    }
    AlertV2.prototype.componentDidMount = function () {
        var _this = this;
        this.setState({
            show: true
        }, function () {
            if (_this.timer_removeDom) {
                clearTimeout(_this.timer_removeDom);
            }
            _this.timer_removeDom = setTimeout(function () {
                _this.setState({
                    show: false
                });
            }, 2000);
        });
    };
    AlertV2.prototype.componentWillUnmount = function () {
        if (this.timer_removeDom) {
            clearTimeout(this.timer_removeDom);
        }
    };
    AlertV2.prototype.renderContHtml = function () {
        if (this.props.type == 'html') {
            return { __html: this.props.text };
        }
        else {
            return null;
        }
    };
    AlertV2.prototype.renderContText = function () {
        if (this.props.type == 'text') {
            return this.props.text;
        }
        else {
            return null;
        }
    };
    AlertV2.prototype.render = function () {
        return (React.createElement(CSS_Transition, { show: this.state.show, typeClass: 'Zoom' },
            React.createElement("div", { className: "global-alertV2", style: this.props.style ? this.props.style : null },
                React.createElement("div", { ref: this.boxCont, className: 'global-alertV2-textCont', dangerouslySetInnerHTML: this.renderContHtml() }, this.renderContText()))));
    };
    return AlertV2;
}(React.PureComponent));
