var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
require('./styles.css');
var Tabbar = /** @class */ (function (_super) {
    __extends(Tabbar, _super);
    function Tabbar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Tabbar.prototype.render = function () {
        var _this = this;
        var tabsView = this.props.tabs.map(function (tab, index) {
            var color = { 'color': '#333' };
            //const color = this.props.selectedIndex === index ? { color: this.props.selectedColor } : { color: this.props.color };
            var icon = _this.props.selectedIndex === index ? tab.selectedIcon : tab.icon;
            return (React.createElement("div", { key: index, className: 'tab', style: color, onClick: function () { return _this.props.onselect && _this.props.onselect(index); } },
                React.createElement("img", { className: "tab-icon", src: icon }),
                React.createElement("div", { style: { color: _this.props.selectedIndex === index ? '#333333' : '' } }, tab.name)));
        });
        return (React.createElement("div", { className: "tabbar-container" }, tabsView));
    };
    Tabbar.defaultProps = {
        selectedIndex: 0,
        color: '#397BF6',
        selectedColor: '#646D80',
        tabs: [],
    };
    return Tabbar;
}(React.PureComponent));
export default Tabbar;
