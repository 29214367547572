var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as qs from 'qs';
require('./static/fontNoto.css');
import App from './App';
import auth from './service/wechatAuth';
import { errorLog, getReopenToken } from './service/index';
import * as detect from 'mobile-detect';
import { iosAndroid } from "./lib/appOc";
import { isApp } from "./lib/utils";
import { getUserinfo } from './service/index';
import { isCN } from "./service/untiApi";
import { Provider } from 'react-redux';
import store from './store';
import webStocket from './webSocket';
import is from './lib/ua';
import { history } from './routes';
import { AESEncrypt } from './lib/crypto';
import { hex_md5 } from './lib/md5';
import { getApiOuth } from './service/apiLock';
import { getVipType } from './service/index';
import './App.css';
require('raf-polyfill');
require('whatwg-fetch');
require('core-js/es/map');
require('core-js/es/set');
require('core-js/es/promise');
require('./lib/wx');
require('./lib/tinker/index');
//添加数组的原型方法 includes 兼容安卓5.x
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (valueToFind, fromIndex) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }
            var o = Object(this);
            var len = o.length >>> 0;
            if (len === 0) {
                return false;
            }
            var n = fromIndex | 0;
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
            }
            while (k < len) {
                if (sameValueZero(o[k], valueToFind)) {
                    return true;
                }
                k++;
            }
            return false;
        }
    });
}
var isMini = false;
if (/MicroMessenger/i.test(navigator.userAgent)) {
    try {
        window['wx'].miniProgram.getEnv(function (res) {
            isMini = res.miniprogram;
        });
    }
    catch (e) {
    }
}
window['isPrevent'] = function (e) {
    e.preventDefault();
};
//长链接启动
webStocket();
//App刷新H5机制
if (isApp) {
    if (is.iPhone) {
        window['setupWebViewJavascriptBridge'](function (bridge) {
            bridge.registerHandler('checkReopenToken', function (data, responseCallback) {
                getReopenToken({ key: new Date().getTime().toString() }).success(function (res) {
                    responseCallback(res.data.datas ? res.data.datas : '');
                }).start();
            });
        });
    }
    if (is.iPhone) {
        window['setupWebViewJavascriptBridge'](function (bridge) {
            bridge.registerHandler('tabbar', function (data, responseCallback) {
                var index = data.index;
                sessionStorage.removeItem('groupIdf');
                if (sessionStorage.getItem('strategyCache')) {
                    sessionStorage.removeItem('strategyCache');
                }
                if (index == 0) {
                    history.push('/');
                }
                else if (index === 1) {
                    history.push('/fundHome');
                }
                else if (index == 2) {
                    history.push('/favorite');
                }
                else if (index == 3) {
                    history.push('/chooseindex');
                }
                else if (index == 4) {
                    history.push('/me');
                }
                responseCallback(index);
            });
        });
    }
    else {
        window['dsBridge'].register('tabbar', function (l, r) {
            var index = JSON.parse(l).index;
            sessionStorage.removeItem('groupIdf');
            if (sessionStorage.getItem('strategyCache')) {
                sessionStorage.removeItem('strategyCache');
            }
            if (index == 0) {
                history.push('/');
            }
            else if (index === 1) {
                history.push('/fundHome');
            }
            else if (index == 2) {
                history.push('/favorite');
            }
            else if (index == 3) {
                history.push('/chooseindex');
            }
            else if (index == 4) {
                history.push('/me');
            }
        });
    }
}
function getIsCn() {
    // 境外IP
    var promise = new Promise(function (reslove, reject) {
        isCN().success(function (res) {
            store.dispatch({
                type: 'change_userIP',
                value: res.data.datas.ip
            });
            sessionStorage.setItem('isCn', res.data.datas.isCN);
            sessionStorage.setItem('isCnMsg', res.data.datas.msg ? res.data.datas.msg : '');
            sessionStorage.setItem('isHKMsg', res.data.datas.msgHk ? res.data.datas.msgHk : '');
            sessionStorage.setItem('isPriceDataMsg', res.data.datas.priceDate ? res.data.datas.priceDate : null);
            reslove('OK');
        }).start().catch(function (err) {
            reslove('OK');
        });
    });
    return promise;
}
function getUserinfo_await() {
    var promise = new Promise(function (reslove, reject) {
        getUserinfo().success(function (res) {
            reslove('OK');
        }).start().catch(function (err) {
            reslove('OK');
        });
    });
    return promise;
}
// 错误信息回收
window.addEventListener('error', function (e) {
    var dom = document.querySelector('#app>div');
    var md = new detect(window.navigator.userAgent);
    var lastRoute = store.getState().routeLast_pathName;
    var usId;
    try {
        usId = localStorage.getItem('u');
        errorLog({
            device: md.mobile() + ',' + md.phone() + ',' + md.userAgent() + ',' + md.os(),
            service: 'moble-web',
            container: isMini ? 'miniWechat' : isApp ? 'app' : 'wechat',
            env: process.env.NODE_ENV,
            pathName: lastRoute ? JSON.stringify(lastRoute) : '',
            isWhite: dom ? '0' : '1',
            errorMsg: JSON.stringify({
                usId: usId,
                path: window.location.href,
                message: e.message,
                filename: e.filename,
                lineno: e.lineno,
                colno: e.colno,
                eventPhase: e.eventPhase,
                bubbles: e.bubbles,
                cancelable: e.cancelable,
                defaultPrevented: e.defaultPrevented,
                composed: e.composed,
                timeStamp: e.timeStamp,
                error: e.error.stack ? e.error.stack : e.error,
            })
        }).success(function (res) {
        }).start();
    }
    catch (e) {
    }
}, true);
// 进入项目的业务逻辑
var pathname = qs.parse(window.location.pathname, { ignoreQueryPrefix: true });
var inMyApp = navigator.userAgent.indexOf('zhishi') != -1;
var notAuth_pathArr = ['/vipGiftCard', '/newscastCont'];
var returnRenderApp = ['/LiveStream', '/liveStream'];
//判断是否在微信中，如果不在微信中 直接跳过授权
function renderChart() {
    var urls = window.location.pathname;
    var userAgent = navigator.userAgent.toLowerCase();
    var ret = false;
    for (var i = 0; i < returnRenderApp.length; i++) {
        if (urls == returnRenderApp[i] && userAgent.indexOf('micromessenger') === -1) {
            ret = true;
        }
    }
    return ret;
}
if (inMyApp) {
    renderApp();
    iosAndroid('getToken', {});
}
else {
    if (process.env.NODE_ENV !== 'production') {
        var query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (query.dev) {
            localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI1MjM2QSIsInN1YiI6IntcImxhc3RMb2dpblRpbWVcIjoxNjU4MjEzNjQ0MjAyLFwiaWRcIjoxODY1fSIsImlhdCI6MTY1ODIxMzY0NCwiZXhwIjoxOTczNTczNjQ0fQ.JuMfcytOS-hA_L27X6xjW1xV6-tqyMIpLwEZAzBTg-Q');
            renderApp();
        }
        else {
            var goAuth = function () {
                auth().then(function () {
                    var query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                    if (query.platform === 'mini') {
                        localStorage.setItem('platform', 'mini');
                    }
                    getUserinfo().success(function (res) {
                        localStorage.setItem('u', res.data.datas.id);
                        renderApp();
                    }).start().catch(function (err) {
                        renderApp();
                    });
                }).catch(function (err) { });
            };
            if (renderChart() || Object.keys(pathname)[0].slice(1, 8) == 'hbStock' || Object.keys(pathname)[0].indexOf('hbindustryIndex') != -1 || Object.keys(pathname)[0].indexOf('HbPDFList') != -1 || Object.keys(pathname)[0].indexOf('hbPdfPage') != -1 || Object.keys(pathname)[0].indexOf('dupont-compare') != -1 || Object.keys(pathname)[0].indexOf('zhisir') != -1 || Object.keys(pathname)[0].indexOf('downPage') != -1 || Object.keys(pathname)[0].indexOf('industry') != -1 || Object.keys(pathname)[0].indexOf('depthData') != -1 || Object.keys(pathname)[0].indexOf('InstitutionalList') != -1 || Object.keys(pathname)[0].indexOf('InstitutionalDetail') != -1) {
                renderApp();
            }
            else {
                if (notAuth_pathArr.includes(location.pathname)) {
                    if (window.location.href.includes('state=auth')) {
                        goAuth();
                    }
                    else {
                        renderApp();
                    }
                }
                else {
                    goAuth();
                }
            }
        }
    }
    else {
        if (localStorage.getItem('token') == 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI1MjM2QSIsInN1YiI6IntcImdlbmRlclwiOjEsXCJnemhPcGVuSWRcIjpcIm83emhMNkxXWDN4TTMzZVFHVjgxU3dlR1QyVElcIixcImlkXCI6MTAsXCJuaWNrTmFtZVwiOlwibnVsbFwiLFwicG9ydHJhaXRcIjpcImh0dHA6Ly90aGlyZHd4LnFsb2dvLmNuL21tb3Blbi92aV8zMi9EWUFJT2dxODNlcmxhcHpGR1UzaWJ2QzJYeU00QkV6WWNjQzRtVnI0TkpyQ1d1RHg0b2ljR0JmdWp0ZGdHWTVrSGVscmNUQzQ2Z2ppYURPN2ljamF1eEc0aWJBLzEzMlwiLFwidW5pb25JZFwiOlwib2tBWTg2QnVEbmRpc3dVSEdzUjlDajY3Xzk5QVwifSIsImlhdCI6MTU2NTE0OTg0NywiZXhwIjoxODgwNTA5ODQ3fQ.LxrVDTcJ6dpLSlVUxXve0RWqEFZVBeMJCqGM1DEwiSk') {
            localStorage.removeItem('token');
        }
        if (renderChart() || Object.keys(pathname)[0].slice(1, 8) == 'hbStock' || Object.keys(pathname)[0].indexOf('hbindustryIndex') != -1 || Object.keys(pathname)[0].indexOf('HbPDFList') != -1 || Object.keys(pathname)[0].indexOf('hbPdfPage') != -1 || Object.keys(pathname)[0].indexOf('dupont-compare') != -1 || Object.keys(pathname)[0].indexOf('zhisir') != -1 || Object.keys(pathname)[0].indexOf('downPage') != -1 || Object.keys(pathname)[0].indexOf('industry') != -1 || Object.keys(pathname)[0].indexOf('depthData') != -1 || Object.keys(pathname)[0].indexOf('InstitutionalList') != -1 || Object.keys(pathname)[0].indexOf('InstitutionalDetail') != -1) {
            renderApp();
        }
        else {
            var goAuth = function () {
                auth().then(function () {
                    //埋点AB侧
                    getUserinfo().success(function (res) {
                        localStorage.setItem('u', res.data.datas.id);
                        var query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                        if (query.platform === 'mini') {
                            localStorage.setItem('platform', 'mini');
                        }
                        renderApp();
                        //AB侧
                        var so = query.so;
                        var abNo = res.data.datas.abTestBatchNo;
                        window['upSaveDian_so'] = so;
                        window['upSaveDian_abNo'] = abNo;
                        if (abNo && so) {
                            // upSaveDian('home', '进入公众号', '进入公众号', null, null, so, abNo)
                        }
                    }).start();
                });
            };
            if (notAuth_pathArr.includes(location.pathname)) {
                if (window.location.href.includes('state=auth')) {
                    renderApp();
                }
                else {
                    goAuth();
                }
            }
            else {
                goAuth();
            }
        }
    }
}
export function handleStringToArr_length(string, step) {
    var r = [];
    for (var i = 0, len = string.length; i < len; i += step) {
        r.push(string.substring(i, i + step));
    }
    return r;
}
function getApiLock() {
    return new Promise(function (reslove, reject) {
        getApiOuth().success(function (res) {
            var timer = new Date().getTime();
            sessionStorage.setItem('userToken', res.data.datas);
            var ApiList = handleStringToArr_length(res.data.datas, 8);
            var AESHttpData = AESEncrypt(ApiList[timer % 10], 'vGEZCiIXRIImAWSv');
            store.dispatch({
                type: 'GET_APILOCK_SUCESS',
                value: {
                    key: hex_md5(timer + AESHttpData),
                    timer: timer
                }
            });
            reslove('ok');
        }).start();
    });
}
//是否是vip
function getVipTypeVal() {
    return new Promise(function (reslove, reject) {
        getVipType().success(function (res) {
            sessionStorage.setItem('vipType', res.data.datas.vipType);
            reslove('ok');
        }).start().catch(function (err) {
            reslove('OK');
        });
    });
}
//renderApp
export function renderApp() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(isApp && window.localStorage.getItem('token'))) return [3 /*break*/, 2];
                    return [4 /*yield*/, getUserinfo_await()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [4 /*yield*/, getApiLock()];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, getIsCn()];
                case 4:
                    _a.sent();
                    if (!!renderChart()) return [3 /*break*/, 6];
                    return [4 /*yield*/, getVipTypeVal()];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    ReactDOM.unmountComponentAtNode(document.getElementById('app'));
                    ReactDOM.render(React.createElement(Provider, { store: store },
                        React.createElement(App, null)), document.getElementById('app'));
                    return [2 /*return*/];
            }
        });
    });
}
