var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import alert from "./lib/alert";
import { upSavePointer } from "./lib/utils";
import { getStockAccounIloginSec, getStockAccounInitOpenUser } from "./service";
import store from "./store";
import * as qs from 'qs';
import is from "./lib/ua";
var stockAccountConfig_huaxi = {
    name: '华西证券',
    //原app Url
    //https://z.hx168.com.cn/h5/m/open/views/account/index.html?cooperate_flag=HXZQ&app_origin=zscf&app_market=h5
    creatUrl: 'https://m.touker.com/marketing/activity/KJFG.htm?channel=VZSXG',
    HuaxicreatUrl: 'https://z.hx168.com.cn/h5/m/open/views/account/index.html?cooperate_flag=HXZQ&app_origin=zscf&app_market=h5',
    creatUrlText: 'https://soft.thinkive.com:60080/m/open/views/account/index.html?cooperate_flag=HXZQ&app_origin=HXZH&app_market=h5',
    rootPathText: 'https://kftest.hx168.com.cn/h5/hqTrade/',
    rootPath: 'https://ex.hx168.com.cn/h5/',
    home: '#/pages/index/index',
    handleUrl: '#/pages/trade/',
};
function isMiniApp() {
    return new Promise(function (resolve) {
        var ua = navigator.userAgent.toLowerCase();
        if (/MicroMessenger/i.test(ua)) {
            try {
                Window['wx'].miniProgram.getEnv(function (res) {
                    if (res.miniprogram) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            catch (error) {
                resolve(false);
            }
        }
        else {
            resolve(false);
        }
    });
}
var generateHX_url = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var isMini, rootPath, reactUrl, HuaxicreatUrl, handleInfo, query, openUrl;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, isMiniApp()];
            case 1:
                isMini = _a.sent();
                rootPath = process.env.NODE_ENV == 'production' ? stockAccountConfig_huaxi.rootPath : stockAccountConfig_huaxi.rootPath;
                reactUrl = process.env.NODE_ENV == 'production' ? stockAccountConfig_huaxi.creatUrl : stockAccountConfig_huaxi.creatUrl;
                HuaxicreatUrl = process.env.NODE_ENV == 'production' ? stockAccountConfig_huaxi.HuaxicreatUrl : stockAccountConfig_huaxi.HuaxicreatUrl;
                handleInfo = {
                    home: function () {
                        getStockAccounIloginSec().success(function () { console.log('————登录券商首页————'); }).start();
                        var url = rootPath + "#/pages/index/index";
                        debugger;
                        return url;
                    },
                    creatAccount: function () {
                        //初始化
                        upSavePointer([
                            { name: 'event_name', value: '开户' },
                            { name: 'event_ext1', value: '跳转券商开户页' },
                        ]);
                        getStockAccounInitOpenUser().success(function () { console.log('————初始化开户————'); }).start();
                        var userInfo = store.getState().userInfo;
                        var userIP = store.getState().userIP;
                        var url = HuaxicreatUrl + "&thirdUserId=" + userInfo.id + "&ip=" + userIP + "&imsi=NA&hideHeader=1";
                        return url;
                    },
                    buy: function () {
                        var userIP = store.getState().userIP;
                        var url = rootPath + "#/pages/trade/index?tradeCode=01&stockCode=" + params.stockCode + "&ip=" + userIP + "&imsi=NA&hideHeader=1";
                        return url;
                    },
                    sell: function () {
                        var userIP = store.getState().userIP;
                        var url = rootPath + "#/pages/trade/index?tradeCode=02&stockCode=" + params.stockCode + "&ip=" + userIP + "&imsi=NA&hideHeader=1";
                        return url;
                    },
                    cancel: function () {
                        var userIP = store.getState().userIP;
                        var url = rootPath + "#/pages/trade/index?tradeCode=03&stockCode=" + params.stockCode + "&ip=" + userIP + "&imsi=NA&hideHeader=1";
                        return url;
                    },
                    hold: function () {
                        var userIP = store.getState().userIP;
                        var url = rootPath + "#/pages/trade/index?tradeCode=04&stockCode=" + params.stockCode + "&ip=" + userIP + "&imsi=NA&hideHeader=1";
                        return url;
                    },
                    entrust: function () {
                        var userIP = store.getState().userIP;
                        var url = rootPath + "#/pages/trade/index?tradeCode=05&stockCode=" + params.stockCode + "&ip=" + userIP + "&imsi=NA&hideHeader=1";
                        return url;
                    }
                };
                if (isMini) {
                    alert('请前往【芝士财富】公众号查看');
                    return [2 /*return*/];
                }
                else {
                    query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                    openUrl = handleInfo[params.type]();
                    if (query.openType == '1' && is.android) {
                        window.location.href = openUrl;
                    }
                    else {
                        window.open(openUrl);
                    }
                }
                return [2 /*return*/];
        }
    });
}); };
export default generateHX_url;
