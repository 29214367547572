var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CSS_Transition from '../../components/cssTransition/index';
require('./style.css');
export default function alertNotice(text, style) {
    var divDOM = document.createElement('div');
    divDOM.id = 'alertNotice';
    var dom = document.querySelector('#alertNotice');
    if (dom) {
        document.body.removeChild(dom);
        document.body.appendChild(divDOM);
    }
    else {
        document.body.appendChild(divDOM);
    }
    ReactDOM.render(React.createElement(AlertNotice, { text: text, style: style }), divDOM);
}
var AlertNotice = /** @class */ (function (_super) {
    __extends(AlertNotice, _super);
    function AlertNotice() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            show: false
        };
        _this.timer_removeDom = null;
        return _this;
    }
    AlertNotice.prototype.componentDidMount = function () {
        this.setState({
            show: true
        });
    };
    AlertNotice.prototype.componentWillUnmount = function () {
        if (this.timer_removeDom) {
            clearTimeout(this.timer_removeDom);
        }
    };
    AlertNotice.prototype.close = function () {
        var _this = this;
        this.setState({
            show: false
        }, function () {
            if (_this.timer_removeDom) {
                clearTimeout(_this.timer_removeDom);
            }
            _this.timer_removeDom = setTimeout(function () {
                var dom = document.querySelector('#alertNotice');
                if (dom) {
                    document.body.removeChild(dom);
                }
            }, 1000);
        });
    };
    AlertNotice.prototype.render = function () {
        var _this = this;
        return (React.createElement(CSS_Transition, { show: this.state.show, typeClass: 'Zoom' },
            React.createElement("div", { className: "global-alertNotice", style: this.props.style ? this.props.style : null },
                React.createElement("div", { className: 'global-alertNotice-textCont' }, this.props.text),
                React.createElement("div", { className: 'global-alertNotice-btn', onClick: function () { _this.close(); } }, "\u77E5\u9053\u4E86"))));
    };
    return AlertNotice;
}(React.PureComponent));
