var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import './style.css';
var CSS_Transition = /** @class */ (function (_super) {
    __extends(CSS_Transition, _super);
    function CSS_Transition(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            show: props.show,
            typeClass: props.typeClass
        };
        return _this;
    }
    CSS_Transition.prototype.componentWillReceiveProps = function (props) {
        if (props.show !== this.props.show || this.props.typeClass !== props.typeClass) {
            this.setState({
                show: props.show,
                typeClass: this.state.typeClass
            });
        }
    };
    CSS_Transition.prototype.render = function () {
        return (React.createElement(CSSTransition, { in: this.state.show, classNames: this.state.typeClass ? this.state.typeClass : '', timeout: this.props.typeClass ? 1000 : 0, unmountOnExit: true }, this.props.children));
    };
    return CSS_Transition;
}(React.Component));
export default CSS_Transition;
