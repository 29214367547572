import ReconnectingWebSocket from 'reconnecting-websocket';
import alertV2 from './lib/alertV2';
import is from './lib/ua';
import { isApp } from "./lib/utils";
var webSocket_ZS_options = {
    // WebSocket?: any; // WebSocket 构造函数，如果没有提供，默认为全局 WebSocket :undefined
    // maxReconnectionDelay?: number; // 重新连接之间的最大延迟（以毫秒为单位）:10000
    // minReconnectionDelay?: number; // 重新连接之间的最小延迟（以毫秒为单位 :1000 + Math.random() * 4000
    // reconnectionDelayGrowFactor?: number; // 重连延迟增长的速度  :1.3
    // minUptime?: number; // 将连接视为稳定的最小时间（以毫秒为单位） :5000
    // connectionTimeout?: number; // 如果此时间后未连接，则重试连接，以毫秒为单位 : :4000
    // maxRetries?: number; // 最大重试次数  :Infinity
    // maxEnqueuedMessages?: number; // 在重新连接之前要缓冲的最大消息数 :Infinity
    // startClosed?: boolean; // 以CLOSED状态启动websocket，调用`.reconnect()`进行连接 :false
    // debug?: boolean; // 启用调试输出  :false
    WebSocket: window['webSocket_ZS'],
    connectionTimeout: 4000,
    maxReconnectionDelay: 10000,
    minReconnectionDelay: 2000,
    debug: false
};
//长链接
var timer_heartbeat = null;
//关闭长链接
function websocket_close() {
    if (timeOnError) {
        clearTimeout(timeOnError);
    }
    if (timerReWebSocket) {
        clearTimeout(timerReWebSocket);
    }
    if (timer_heartbeat) {
        clearTimeout(timer_heartbeat);
    }
    if (window['webSocket_ZS']) {
        window['webSocket_ZS'].close();
    }
}
var URL_arr = process.env.NODE_ENV !== 'production' ? [
    "wss://wstest.zsxg.cn",
    "wss://wstest1.zsxg.cn",
    "wss://wstest2.zsxg.cn",
    "wss://wstest3.zsxg.cn",
    "wss://wstest4.zsxg.cn",
] : [
    "wss://ws.zsxg.cn",
    "wss://ws1.zsxg.cn",
    "wss://ws2.zsxg.cn",
    "wss://ws3.zsxg.cn",
    "wss://ws4.zsxg.cn",
];
var localURL_index = sessionStorage.getItem('websocketURL_NOTINDEX');
var URL_index = localURL_index ? localURL_index == '4' ? 0 : Number(localURL_index) + 1 : 0;
var timeOnError = null;
export default function webSocket_action() {
    websocket_close();
    function creatWebSocket() {
        window['webSocket_ZS'] = new ReconnectingWebSocket(URL_arr[URL_index], [], webSocket_ZS_options);
        window['webSocket_ZS'].onopen = function () {
            if (window['webSocket_ZS'].readyState == 1) {
                heartbeat();
            }
        };
        //连接发生错误的回调方法
        window['webSocket_ZS'].onerror = function (e) {
            websocket_close();
            timeOnError = setTimeout(function () {
                if (window['webSocket_ZS'].readyState != 1) {
                    sessionStorage.setItem('websocketURL_NOTINDEX', String(URL_index));
                    if (URL_index == 4) {
                        URL_index = 0;
                    }
                    else {
                        URL_index++;
                    }
                    webSocket_action();
                }
            }, 3000);
        };
    }
    //重生  30s一次
    function heartbeat() {
        timer_heartbeat = setTimeout(function () {
            if (window['webSocket_ZS'].readyState == 1) {
                window['webSocket_ZS'].send('HEART');
            }
            else {
                window['webSocket_ZS'].reconnect();
            }
            heartbeat();
        }, 30000);
    }
    //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
    window.onbeforeunload = function () {
        websocket_close();
    };
    creatWebSocket();
}
//过滤重连WebSocket
var timerReWebSocket = null;
function reconnectWebSocket() {
    if (timerReWebSocket) {
        clearTimeout(timerReWebSocket);
    }
    if (timer_heartbeat) {
        clearTimeout(timer_heartbeat);
    }
    timerReWebSocket = setTimeout(function () {
        window['webSocket_ZS'].reconnect();
    }, 500);
}
if (!isApp) {
    if (navigator.connection) {
        navigator.connection.addEventListener('change', function (e) {
            if (navigator.onLine) {
                reconnectWebSocket();
            }
        });
    }
    else {
        window.addEventListener("online", function () {
            reconnectWebSocket();
        });
    }
    window.addEventListener("offline", function () {
        websocket_close();
        alertV2('网络已断开');
    });
}
else {
    if (is.iPhone) {
        window['setupWebViewJavascriptBridge'](function (bridge) {
            bridge.registerHandler('breakWebSocket', function (data, responseCallback) {
                reconnectWebSocket();
            });
        });
        window['setupWebViewJavascriptBridge'](function (bridge) {
            bridge.registerHandler('closeWebSocket', function (data, responseCallback) {
                websocket_close();
            });
        });
    }
    else {
        window['dsBridge'].register('breakWebSocket', function (l) {
            reconnectWebSocket();
        });
        window['dsBridge'].register('closeWebSocket', function (l) {
            websocket_close();
        });
    }
}
function watchWindowIsVisible() {
    document.addEventListener("visibilitychange", function () {
        if (document.visibilityState == "visible") {
            //回到窗口
            reconnectWebSocket();
        }
        if (document.visibilityState === 'hidden') { // 当页面由前端运行在后端时，出发此代码
            websocket_close();
        }
    });
}
try {
    //1.查看窗口是否隐藏起来 停止长链接
    if (isApp) {
        if (isApp) {
            var ua = window.navigator.userAgent ? window.navigator.userAgent.split('zhishi Version/')[1] ? window.navigator.userAgent.split('zhishi Version/')[1] : '' : '';
            if (ua.length == 10) {
                if (Number(ua) < 2022022810) {
                    watchWindowIsVisible();
                }
            }
            else {
                if (Number(ua) < 75) {
                    watchWindowIsVisible();
                }
            }
        }
    }
    else {
        watchWindowIsVisible();
    }
}
catch (e) {
}
