var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import './styles.css';
import { getTempPopup } from '../../service/index';
import { history } from "../../routes";
import CSS_Transition from '../cssTransition/index';
var newUser = /** @class */ (function (_super) {
    __extends(newUser, _super);
    function newUser(props) {
        var _this = _super.call(this, props) || this;
        _this.mskModal = null;
        _this.state = {
            tempPopup: null,
            isShowParty: false,
            isShowParty_Content: false
        };
        return _this;
    }
    newUser.prototype.componentDidMount = function () {
        this.getTempPopup();
    };
    newUser.prototype.getTempPopup = function () {
        var _this = this;
        getTempPopup().success(function (res) {
            _this.setState({
                tempPopup: res.data.datas
            }, function () {
                var notShowNewUser = ['/', '/chooseindex', '/fundHome', '/favorite', '/me'];
                if (notShowNewUser.indexOf(history.location.pathname) != -1) {
                    if (_this.state.tempPopup != null && _this.state.tempPopup.activityImg != '') {
                        _this.setState({
                            isShowParty: true
                        }, function () {
                            _this.setState({
                                isShowParty_Content: true
                            });
                        });
                    }
                }
                else {
                    _this.setState({
                        isShowParty: false
                    }, function () {
                        _this.setState({
                            isShowParty_Content: false
                        });
                    });
                }
            });
        }).start();
    };
    newUser.prototype.onRef_mskModal = function (ref) {
        this.mskModal = ref;
    };
    newUser.prototype.goParty = function () {
        var _this = this;
        this.closeShowParty(function () {
            if (_this.state.tempPopup.url && (_this.state.tempPopup.url.includes("http") || _this.state.tempPopup.url.includes("https"))) {
                window.open(_this.state.tempPopup.url);
            }
            else if (_this.state.tempPopup.url && _this.state.tempPopup.url.includes("#")) {
                _this.closeShowParty();
            }
            else {
                history.push(_this.state.tempPopup.url ? _this.state.tempPopup.url : '/');
            }
        });
    };
    newUser.prototype.closeShowParty = function (callBack) {
        var _this = this;
        this.setState({
            isShowParty_Content: false
        }, function () {
            setTimeout(function () {
                _this.setState({ isShowParty: false }, function () {
                    callBack && callBack();
                });
            }, 300);
        });
    };
    newUser.prototype.render = function () {
        var _this = this;
        if (this.state.isShowParty) {
            return (React.createElement("div", { className: 'partyMskShow' },
                React.createElement(CSS_Transition, { show: this.state.isShowParty_Content, typeClass: 'Zoom' },
                    React.createElement("div", { className: 'partyMskShow_box' },
                        React.createElement("div", { className: 'partyMskShow_img', onClick: function () { _this.goParty(); }, style: { backgroundImage: "url(" + this.state.tempPopup.activityImg } }),
                        React.createElement("div", { className: "partyMskShow_close", onClick: function () { _this.closeShowParty(); } },
                            React.createElement("img", { src: require('../../assets/images/close_icon_msk.png'), alt: "" }))))));
        }
        else {
            return null;
        }
    };
    return newUser;
}(React.PureComponent));
export default newUser;
